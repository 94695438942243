#root, html {
  background-color: #212529;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.lime .progress-bar {
  background-color: rgb(50, 205, 50);
}
.red .progress-bar {
  background-color: rgb(255, 0, 0);
}
.amber .progress-bar {
  background-color: rgb(255, 150, 24);
}
